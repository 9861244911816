import {axiosApi} from "./api";

export const getLoanList = (skip, span, field, term, active, phase) => {
    return axiosApi.get(`/api/loan?skip=${skip}&span=${span}&field=${field}&term=${term}&active=${active}&phase=${phase}`);
};

export const saveLoan = (data) => {
    return axiosApi.post("/api/loan", data);
};

export const updateLoan = (data) => {
    return axiosApi.put("/api/loan", data);
};