import * as actionTypes from "./actionTypes";
import * as participantService from "../../services/participant";
import {logout} from "../../services/auth";
import history from "../../component/history";

export const storeProfile = (data) => {
  return {
    type: actionTypes.STORE_PROFILE,
    profile: (data && data.results && data.results.length>0) ? data.results[0] : {}
  };
};
export const getProfile = () => {
  return (dispatch) => {
    participantService
      .getProfile()
      .then((response) => {        
        dispatch(storeProfile(response.data));
      })
      .catch((error) => {
        if(error && error.response && error.response.status == 401){
          logout();
        } else {
          const errMsg = (error && error.response && error.response.msg) ? error.response.msg : "Unable to load your profile!";
          dispatch(errorPopup(errMsg));
        }
      });
  };
};


export const storeParticipantList = (data, skip) => {
  return {
    type: actionTypes.STORE_PARTICIPANT,
    participants: data,
    skip
  };
};
export const updateParticipantList = (data, skip) => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT,
    participants: data,
    skip
  };
};

export const getParticipants = (skip, span, field, term, active) => {
  return (dispatch) => {
    participantService
      .getParticipantList(skip, span, field, term, active)
      .then((res) => {    
        if(res && res.data) {
          if(skip)
            dispatch(updateParticipantList(res.data.results, res.data.skip));
          else
            dispatch(storeParticipantList(res.data.results, res.data.skip));
        }
      })
      .catch((error) => { 
        const errMsg = (error && error.response && error.response.msg) ? error.response.msg : "Unable to load participants!";
        dispatch(errorPopup(errMsg));
      });
  };
};


export const errorPopup = (msg) => {
  return {
    type: actionTypes.OPEN_ERROR_POPUP,
    msg
  };
};

export const closePopup = () => {
  return (dispatch) => dispatch({
    type: actionTypes.CLOSE_ERROR_POPUP
  });
};

export const checkProfile = () => {
  return (dispatch) => {
    participantService
      .getProfile()
      .then((response) => {        
        history.push("/dashboard");
      })
      .catch((error) => {
        if(error && error.response && error.response.status == 401){
          logout();
        }
      });
  };
};

export const setActiveScreen = (data) => {
  return (dispatch) => {
    console.log("Screen - "+data);
    return dispatch({
      type: actionTypes.SET_ACTIVE_SCREEN,
      screen: data ? data : ""
    });
  };
};