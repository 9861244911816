import * as actionTypes from "./actionTypes";
import * as phaseService from "../../services/phase";

export const storePhaseList = (data) => {
  return {
    type: actionTypes.STORE_PHASE,
    phase: data
  };
};

export const getPhases = () => {
  return (dispatch) => {
    phaseService
      .getPhase()
      .then((res) => {    
        if(res && res.data) {
          dispatch(storePhaseList(res.data.results));
        }
      })
      .catch((error) => { 
        
      });
  };
};

export const selectPhase = (data) => {
  return {
    type: actionTypes.SELECT_PHASE,
    selectedPhase: data
  };
};