import React, {useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Navbar, Button } from 'react-bootstrap';
import { logout } from "../services/auth";
import {connect} from "react-redux";
import {getProfile, getPhases, selectPhase} from "../store/actions/index";
import {savePhase} from "../services/phase";
import {formatDateToYYYYMMDD, formatDateToDDMMYYYY} from "../services/participant";

const Header = (prop) => {
    useEffect(() => {
		if(!prop.profile || (prop.profile && !prop.profile.mobile)) {
			prop.loadProfile();
		}
        prop.loadPhase();
	}, []);

    const [newPhaseDate, setNewPhaseDate] = React.useState("");
    const createNewPhase = () => {
        if(newPhaseDate){
            savePhase({startDate: formatDateToYYYYMMDD(newPhaseDate)})
            .then(() => {
                setNewPhaseDate("");
                prop.loadPhase();
            })
            .catch(() => {

            });
        }
    };
	
    return (<>
        <Navbar bg="light" variant="light" style={{
            justifyContent:"space-between",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            marginLeft: "10px",
            marginRight: "10px"}}>
            <Navbar.Brand href="/">
            <img
                alt=""
                src={"../static/brand-logo.png"}
                width="50"
                height="30"
                className="d-inline-block align-top"
            />{' '}
            <span style={{fontWeight:"bold", paddingLeft:"5px"}}>Makhong Mari</span>
            </Navbar.Brand>
            {(prop.screen==="dashboard") ? null : <button type="button" className="btn btn-light" style={{width:"50%", color:"#17a2b8"}} data-toggle="modal" data-target="#phaseModalLong">
                {(prop.selectedPhase&&prop.selectedPhase.id)
                ? "PHASE "+prop.selectedPhase.id+" - "+formatDateToDDMMYYYY(new Date(prop.selectedPhase.startDate))
                : "Click to select Phase..."}
            </button>}
            <div>
                <span style={{fontSize: "12px", paddingRight:"10px"}}>Welcome {prop.profile.name}!</span>
                <Button variant="outline-info" onClick={logout}>Log Out</Button>{' '}
            </div>
        </Navbar>


    <div className="modal fade" id="phaseModalLong" tabIndex="-1" role="dialog" aria-labelledby="phaseModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="phaseModalLong">Select a Phase</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                        <DatePicker className="form-control" selected={newPhaseDate} dateFormat="dd/MM/yyyy" 
                            onChange={(date) => {setNewPhaseDate(date);}} />
                        </div>
                        <div className="form-group col-md-6">
                            <button type="button" className="btn btn-primary" onClick={createNewPhase}>Create new Phase</button>
                        </div>
                    </div>
                    <ul style={{paddingInlineStart:"0em"}}>
                        {prop.phase.map((p) => {
                            return (<li data-dismiss="modal" key={p.id}
                                style={{cursor:"pointer", background:"#138496", padding:"3px", marginBottom:"6px", color:"white"}}
                                onClick={() => prop.selectPhase(p)}>
                                (Phase {p.id}) {formatDateToDDMMYYYY(new Date(p.startDate))}
                            </li>);
                        })}
                    </ul>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>                    
                </div>
            </div>
        </div>
    </div>
    </div>
    </>);
};

const mapStateToProps = (state) => ({
    phase: state.Phase.phase,
    selectedPhase: state.Phase.selectedPhase,
	profile: state.Participant.profile,
    screen: state.Participant.screen
});

const mapDispatchToProps = (dispatch) => ({
    loadPhase: () => {
        dispatch(getPhases());
    },
	loadProfile: () => {
		dispatch(getProfile());
	},
    selectPhase: (data) => {
        dispatch(selectPhase(data));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);