export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const SET_ACTIVE_SCREEN = "SET_ACTIVE_SCREEN";

export const OPEN_ERROR_POPUP = "OPEN_ERROR_POPUP";
export const CLOSE_ERROR_POPUP = "CLOSE_ERROR_POPUP";

export const STORE_PROFILE = "STORE_PROFILE";

export const UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT";
export const STORE_PARTICIPANT = "STORE_PARTICIPANT";

export const STORE_LOAN = "STORE_LOAN";
export const UPDATE_LOAN = "UPDATE_LOAN";

export const STORE_PHASE = "STORE_PHASE";
export const SELECT_PHASE = "SELECT_PHASE";


export const STORE_TENDER = "STORE_TENDER";
export const UPDATE_TENDER = "UPDATE_TENDER";
export const STORE_TENDER_PAYMENT = "STORE_TENDER_PAYMENT";