import {axiosApi} from "./api";

export const getPhase = () => {
    return axiosApi.get(`/api/phase`);
};

export const savePhase = (data) => {
    return axiosApi.post("/api/phase", data);
};

export const updatePhase = (data) => {
    return axiosApi.put("/api/phase", data);
};