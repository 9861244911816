import React, {useEffect, useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./header";
import $ from 'jquery'; 
import {connect} from "react-redux";
import {getTenders, getTenderPayments, getParticipants, setActiveScreen} from "../store/actions/index";
import {saveTender, updateTender, updateTenderPayment, getPaymentDetail} from "../services/tender";
import {formatDateToYYYYMMDD, formatDateToDDMMYYYY} from "../services/participant";


const Tender = (prop) => { 
    prop.setActiveScreen("tender");
    $('#searchParticipantModalLong').on('hidden.bs.modal', function (e) {
        $("#tenderModalLong").modal('show');
    });
    $('#searchParticipantModalLong').on('show.bs.modal', function (e) {
        $("#tenderModalLong").modal('hide');
    });

    const [currentPeriodRange, setCurrentPeriodRange] = React.useState([]);
    const setCurrentPeriod = (flag) => {
        if(!flag){
            let periods = [];            
            let dateObj = new Date();
            let count = 0;
            while (count<6) {
                periods.push(dateObj.getMonth() +"-"+ dateObj.getFullYear());
                dateObj.setMonth(dateObj.getMonth()+1);
                count = count+1;
            }
            setCurrentPeriodRange(periods);
        } else if(flag==="prev") {
            if(currentPeriodRange && currentPeriodRange.length>0) {
                let periods = [...currentPeriodRange]; 
                let firstEle = periods.at(0);
                firstEle = firstEle.split("-");
                let dateObj = new Date(`${firstEle[0]}-1-${firstEle[1]}`);
                dateObj.setMonth(dateObj.getMonth()-1);
                periods.pop();
                setCurrentPeriodRange([(dateObj.getMonth()-(-1)) +"-"+ dateObj.getFullYear(), ...periods]);
            }
        } else if(flag==="next") {
            if(currentPeriodRange && currentPeriodRange.length>0) {
                let periods = [...currentPeriodRange]; 
                let lastEle = periods.at(periods.length-1);
                lastEle = lastEle.split("-");
                let dateObj = new Date(`${lastEle[0]}-1-${lastEle[1]}`);
                dateObj.setMonth(dateObj.getMonth()+1);
                periods.shift();
                setCurrentPeriodRange([...periods, (dateObj.getMonth()-(-1)) +"-"+ dateObj.getFullYear()]);
            }
        }
    };

    const periodFormat = (period) => {
        if(period){
            const data = period.split("-");
            switch (data[0]) {
                case "1":
                    return `Jan ${data[1]}`;
                case "2":
                    return `Feb ${data[1]}`;
                case "3":
                    return `Mar ${data[1]}`;
                case "4":
                    return `Apr ${data[1]}`;
                case "5":
                    return `May ${data[1]}`;
                case "6":
                    return `Jun ${data[1]}`;
                case "7":
                    return `Jul ${data[1]}`;
                case "8":
                    return `Aug ${data[1]}`;
                case "9":
                    return `Sep ${data[1]}`;
                case "10":
                    return `Oct ${data[1]}`;
                case "11":
                    return `Nov ${data[1]}`;
                case "12":
                    return `Dec ${data[1]}`;
            
                default:
                    return '';
            }
        }
        return '';
    };

    const searchSpan = 10;
    useEffect(() => {
        prop.loadParticipant(0, searchSpan, "", "", "");
		prop.loadTender(0, searchSpan, "", "", "", prop.selectedPhase.startDate, "", "", "");
        setCurrentPeriod();
	}, [prop.selectedPhase]);

    const loadPayments = () => {
        if(prop.tenders && prop.tenders.length>0 
            && currentPeriodRange && currentPeriodRange.length>0) {
                const tenderNos = prop.tenders.map((t) => t.tenderNo);
            prop.loadTenderPayment(tenderNos,currentPeriodRange);
        }
    };
    useEffect(() => {
        loadPayments();
    }, [prop.tenders, currentPeriodRange]);

    let formData = {
        participantId: "", name: "", address: "", mobile: "", tenderNo: null, free: false, 
        tender: false, winner: false, phaseDate: prop.selectedPhase?prop.selectedPhase.startDate:null, active: true,
        createdOn: "", createdBy: "",
        updatedOn: "", updatedBy: ""
    }; 
    const [values, setValues] = React.useState(formData);
    const handleChange = (field, val) => {
        if(field){
            setValues({
            ...values, [field]: val
            });
        }
    };

    const saveChange = () => {
        if(!values.participantId)
            return false;

        if(values.tenderNo){
            const postData = {
                tenderNo: values.tenderNo,
                participantId: values.participantId, mobile: values.mobile, free: values.free, 
                tender: values.tender, winner: values.winner, phaseDate: formatDateToYYYYMMDD(new Date(values.phaseDate)), active: values.active
            };
            
            updateTender(postData)
            .then(() => {
                prop.loadTender(0, searchSpan, "", "", "", prop.selectedPhase.startDate, "", "", "");
            })
            .catch(() => {

            });
        } else {
            const postData = {
                participantId: values.participantId, mobile: values.mobile, free: values.free, 
                tender: values.tender, winner: values.winner, phaseDate: formatDateToYYYYMMDD(new Date(values.phaseDate)), active: true
            };
            
            saveTender(postData)
            .then(() => {
                prop.loadTender(0, searchSpan, "", "", "", prop.selectedPhase.startDate, "", "", "");
            })
            .catch(() => {

            });
        }
        $('#tenderModalLong').modal('hide');
    };

    let searchData = {
        field: "", term: "", active: "", free: "", tender: "", winner: ""
    }; 
    const [search, setSearch] = React.useState(searchData);
    const onSearch = () => {
        const field = search.field,
        active = search.active,
        term = search.term, free = search.free, tender = search.tender, winner = search.winner;
        if((term && field) || active || free || tender || winner){
            setSearch({
                ...search,
                field,
                active,
                term, free, tender, winner
            });
            prop.loadTender(0, searchSpan, field, term, active, prop.selectedPhase.startDate, free, tender, winner);
        }
    };

    const formatStatus = (st) => {
        // if(st===1) return "Yes";
        // else return "No";
        return st===1;
    };

    const viewDetail = (selectedData) => {
        let valToShow = {
            ...values,
            tenderNo: selectedData.tenderNo ? selectedData.tenderNo : null,
            participantId: selectedData.participantId ? selectedData.participantId : null,
            name: selectedData.name ? selectedData.name : "",
            mobile: selectedData.mobile ? selectedData.mobile : null,
            address: selectedData.address ? selectedData.address : "",
            free: selectedData.free===undefined ? false : selectedData.free,
            tender: selectedData.tender===undefined ? false : selectedData.tender,
            winner: selectedData.winner===undefined ? false : selectedData.winner,
            phaseDate: selectedData.phaseDate ? selectedData.phaseDate : null,
            active: selectedData.active===undefined ? false : selectedData.active,
            createdBy: selectedData.createdBy ? selectedData.createdBy : "",
            createdOn: selectedData.createdOn ? selectedData.createdOn : "",
            updatedBy: selectedData.updatedBy ? selectedData.updatedBy : "",
            updatedOn: selectedData.updatedOn ? selectedData.updatedOn : ""
        };
        
        getPaymentDetail(selectedData.tenderNo)
        .then((res) => {
            if(res && res.data && res.data.results && res.data.results.totalPaid){
                setValues({
                    ...valToShow, totalPaid: res.data.results.totalPaid
                });
            } else {
                setValues({
                    ...valToShow, totalPaid: ""
                });
            }
            $('#tenderModalLong').modal('show');
        })
        .catch(() => {
            setValues({
                ...valToShow, totalPaid: ""
            });
            $('#tenderModalLong').modal('show');
        });
    };

    const addNew = () => {
        setValues({
            ...values,
            tenderNo: undefined,
            participantId: "",
            name: "",
            mobile: "",
            address: "",
            free: false,
            tender: false,
            winner: false,
            phaseDate: (prop.selectedPhase)?prop.selectedPhase.startDate:"",
            active: true,
            createdBy: "",
            createdOn: "",
            updatedBy: "",
            updatedOn: "",
            totalPaid: ""
        });
        $('#tenderModalLong').modal('show');
    };

    const loadMore = () => {
        prop.loadTender(prop.skip, searchSpan, search.field, search.term, search.active, prop.selectedPhase.startDate,
            search.free, search.tender, search.winner);
    };

    const onReset = () => {
        setSearch({
                ...search,
                ...searchData
            });
        prop.loadTender(0, searchSpan, "", "", "", prop.selectedPhase.startDate, "", "", "");
    };


    const [tenderPayment, setTenderPayment] = React.useState({});
    const handlePaymentChange = (field, val) => {
        if(field){
            setTenderPayment({
            ...tenderPayment, [field]: val
            });
        }
    };
    const viewPaymentDetail = (selectedData) => {
        setTenderPayment({
            ...tenderPayment,
            tenderNo: selectedData.tenderNo ? selectedData.tenderNo : null,
            participantId: selectedData.participantId ? selectedData.participantId : null,
            mobile: selectedData.mobile ? selectedData.mobile : null,
            name: selectedData.name ? selectedData.name : "",
            period: selectedData.period ? selectedData.period : "",
            paid: selectedData.paid ? selectedData.paid : "",
            balance: selectedData.balance ? selectedData.balance : "",
            updatedBy: selectedData.updatedBy ? selectedData.updatedBy : "",
            updatedOn: selectedData.updatedOn ? selectedData.updatedOn : ""
        });
        $('#tenderPaymentModalLong').modal('show');
    };
    const savePaymentChange = () => {
        if(tenderPayment.tenderNo && tenderPayment.period){
            const postData = {
                tenderNo: tenderPayment.tenderNo,
                participantId: tenderPayment.participantId,
                mobile: tenderPayment.mobile,
                period: tenderPayment.period,
                paid: tenderPayment.paid ? tenderPayment.paid : "0",
                balance: tenderPayment.balance ? tenderPayment.balance : "0"
            };
            
            updateTenderPayment(postData)
            .then(() => {
                loadPayments();
            })
            .catch(() => {

            });
        }
        $('#tenderPaymentModalLong').modal('hide');
    };


    return (<>    
    <Header></Header>   
    <div style={{background:"rgb(255,255,255,0.5)", fontSize:"20px", fontWeight:"900", textAlign:"center", margin:"10px", padding:"5px"}}>
        CHIT FUND
    </div> 
    {prop.selectedPhase&&prop.selectedPhase.id
    ? (
    <div className="container">
        <div className="form-row">
            <div className="form-group col-md-3">
                <select className="form-control" value={search.field} 
                onChange={(evt) => {
                    setSearch({ ...search, field: evt.currentTarget.value });
                }}>
                    <option value="">Search by...</option>
                    <option value="name">Name</option>
                    <option value="mobile">Mobile</option>
                    <option value="tenderNo">Tender No</option>
                </select>
            </div>
            <div className="form-group col-md-3">
                <select className="form-control" value={search.active} 
                onChange={(evt) => {
                    setSearch({ ...search, active: evt.currentTarget.value });
                }}>
                    <option value="">All Status...</option>
                    <option value="true">Only Active</option>
                    <option value="false">Only Inactive</option>
                </select>
            </div>
            <div className="form-group col-md-6">
                <input type="text" className="form-control" placeholder="Enter search text" value={search.term} 
                    onChange={(evt) => {
                        setSearch({ ...search, term: evt.currentTarget.value });
                    }}
                />
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-3">
                {/* <div className="form-group form-check form-control" style={{paddingLeft:"30px"}}> */}
                    {/* <input type="checkbox" className="form-check-input" id="free" checked={search.free}
                    onChange={(evt) => setSearch({ ...search, free: evt.currentTarget.checked })}/>
                    <label className="form-check-label" htmlFor="free">Free</label> */}
                    <select className="form-control" value={search.free} 
                    onChange={(evt) => {
                        setSearch({ ...search, free: evt.currentTarget.value });
                    }}>
                        <option value="">Select Free or Not...</option>
                        <option value="true">Yes - Free</option>
                        <option value="false">Not - Free</option>
                    </select>
                {/* </div> */}
            </div>
            <div className="form-group col-md-3">
                {/* <div className="form-group form-check form-control" style={{paddingLeft:"25px"}}>
                    <input type="checkbox" className="form-check-input" id="tender" checked={search.tender}
                    onChange={(evt) => setSearch({ ...search, tender: evt.currentTarget.checked })}/>
                    <label className="form-check-label" htmlFor="tender">Tender</label>
                </div> */}
                <select className="form-control" value={search.tender} 
                    onChange={(evt) => {
                        setSearch({ ...search, tender: evt.currentTarget.value });
                    }}>
                        <option value="">Select Tender or Not...</option>
                        <option value="true">Yes - Tender</option>
                        <option value="false">Not - Tender</option>
                </select>
            </div>
            <div className="form-group col-md-3">
                {/* <div className="form-group form-check form-control" style={{paddingLeft:"25px"}}>
                    <input type="checkbox" className="form-check-input" id="winner" checked={search.winner}
                    onChange={(evt) => setSearch({ ...search, winner: evt.currentTarget.checked })}/>
                    <label className="form-check-label" htmlFor="winner">Winner</label>
                </div> */}
                <select className="form-control" value={search.winner} 
                    onChange={(evt) => {
                        setSearch({ ...search, winner: evt.currentTarget.value });
                    }}>
                        <option value="">Select Winner or Not...</option>
                        <option value="true">Yes - Winner</option>
                        <option value="false">Not - Winner</option>
                </select>
            </div>    
            <div className="form-group col-md-1">
                <button type="submit" className="btn btn-success" onClick={onSearch}>Search</button>
            </div>
            <div className="form-group col-md-1">
                <button type="submit" className="btn btn-secondary" onClick={onReset}>Reset</button>
            </div>
        </div>

        <button className="btn btn-primary btn-block" type="button" 
        style={{width:"100px", marginBottom:"10px"}} onClick={addNew}
        >
            Add New
        </button>
        <div className="table-container">
        <table className="table" style={{width:"1400px", marginBottom:"0px"}}>
            <thead className="thead-dark">
                <tr>
                <th scope="col" style={{minWidth:"200px"}}># - Name (Mobile)</th>
                <th scope="col">Active</th>
                <th scope="col">Free</th>
                <th scope="col">Tender</th>
                <th scope="col">Winner</th>
                <th style={{width:"760px"}}>
                    <button type="button" className="btn btn-link" onClick={() => setCurrentPeriod("prev")}>{"< Prev"}</button>
                    {currentPeriodRange.map((period) => (
                        <span key={period} style={{display:"inline-block", width:"100px", textAlign:"center"}}>{periodFormat(period)}</span>    
                    ))}
                    <button type="button" className="btn btn-link" onClick={() => setCurrentPeriod("next")}>{"Next >"}</button>
                </th>
                </tr>
            </thead>
            <tbody>
                {prop.tenders.map((p) => {
                    return (
                        <tr key={p.tenderNo}>
                            <td>
                                {p.tenderNo} -                     
                                <button type="button" className="btn btn-link"
                                onClick={() => {viewDetail(p)}}>
                                    {p.name}
                                </button>
                                ({p.mobile})
                            </td>
                            <td>
                                <input type="checkbox" style={{margin:"10px"}} checked={formatStatus(p.active)} disabled/>
                            </td>
                            <td>
                                <input type="checkbox" style={{margin:"10px"}} checked={formatStatus(p.free)} disabled/>
                            </td>
                            <td>
                                <input type="checkbox" style={{margin:"10px"}} checked={formatStatus(p.tender)} disabled/>
                            </td>
                            <td>
                                <input type="checkbox" style={{margin:"10px"}} checked={formatStatus(p.winner)} disabled/>
                            </td>
                            <td>
                                <button type="button" className="btn btn-link" onClick={() => setCurrentPeriod("prev")}>{"< Prev"}</button>
                                {currentPeriodRange.map((period) => (
                                    <button type="button" className="btn btn-link" key={period} style={{width:"100px", textAlign:"center"}}
                                    onClick={() => {viewPaymentDetail(
                                        prop.payments[p.tenderNo+""] && prop.payments[p.tenderNo+""][period]
                                        ? {...prop.payments[p.tenderNo+""][period], name: p.name}
                                        : {tenderNo: p.tenderNo, mobile: p.mobile, name: p.name, period, participantId: p.participantId})
                                    }}>{
                                        (prop.payments[p.tenderNo+""] && prop.payments[p.tenderNo+""][period])
                                        ? (prop.payments[p.tenderNo+""][period].paid) 
                                        : 0
                                    }</button>    
                                ))}
                                <button type="button" className="btn btn-link" onClick={() => setCurrentPeriod("next")}>{"Next >"}</button>
                            </td>
                        </tr>
                    );
                })}                
            </tbody>
        </table>
        </div>
        {prop.tenders && prop.tenders.length===0 
        ?(<div style={{backgroundColor:"rgb(0,0,0,0.5)", width:"100%", color:"white", textAlign:"center"}}>
            No data...
        </div>)
        :
            (prop.completed 
            ? (<div style={{color:"white", backgroundColor:"rgb(0,0,0,0.5)", textAlign:"center"}}>Thats all...</div>)
            : (<button type="button" className="btn btn-link" style={{backgroundColor:"rgb(0,0,0,0.5)", width:"100%"}}
            onClick={loadMore}>
                Load More...
            </button>))
        }
    </div>
    )
    : (<div style={{background:"rgb(0,0,0,0.5)", color:"white", textAlign:"center", margin:"20px"}}>
        Please select a phase to continue...
    </div>)}





    <div className="modal fade" id="tenderModalLong" tabIndex="-1" role="dialog" aria-labelledby="tenderModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="tenderModalLong">Chit Fund</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
                <div className="card-body">     
                    <div className="form-group">
                        <button type="button" className="btn btn-info" style={{width:"100%"}}
                        data-toggle="modal" data-target="#searchParticipantModalLong">
                            {values.name?values.name:"Click to select Participant..."}
                        </button>
                    </div>             
                    <div className="form-group">
                      <input type="text" className="form-control" id="mobile" placeholder="Enter mobile number" disabled
                      value={values.mobile ? values.mobile : ""} 
                      />
                    </div>
                    <div className="form-group">
                      <textarea type="text" row={100} className="form-control" id="address" placeholder="Enter Address" disabled
                      value={values.address} 
                      />
                    </div>
                    <div className="form-group">
                        <label>Phase Starting:</label>
                        <DatePicker className="form-control" selected={values.phaseDate?new Date(values.phaseDate):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="free" checked={values.free}
                      onChange={(evt) => handleChange('free', evt.currentTarget.checked)}/>
                      <label className="form-check-label" htmlFor="free">Free</label>
                    </div> 
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="tender" checked={values.tender}
                      onChange={(evt) => handleChange('tender', evt.currentTarget.checked)}/>
                      <label className="form-check-label" htmlFor="tender">Tender</label>
                    </div> 
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="winner" checked={values.winner}
                      onChange={(evt) => handleChange('winner', evt.currentTarget.checked)}/>
                      <label className="form-check-label" htmlFor="winner">Winner</label>
                    </div> 
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="active" checked={values.active}
                      onChange={(evt) => handleChange('active', evt.currentTarget.checked)}/>
                      <label className="form-check-label" htmlFor="active">Active</label>
                    </div>  
                    {values.tenderNo ? (<div className="form-group">
                        <label>Total Paid Amount:</label>
                      <input type="text" className="form-control" id="total-amt-paid" placeholder="Total Paid Amount" disabled
                      value={(values.totalPaid || values.totalPaid===0) ? values.totalPaid+"" : ""} 
                      />
                    </div>) : null}
                    <div className="form-group">
                        <label>Entry By:</label>
                      <input type="text" className="form-control" id="createdBy" disabled
                      value={values.createdBy} 
                      />
                    </div>
                    <div className="form-group">
                        <label>Entry On:</label>
                        <DatePicker className="form-control" selected={values.createdOn?new Date(values.createdOn):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="form-group">
                        <label>Last Updated By:</label>
                      <input type="text" className="form-control" id="updatedBy" disabled
                      value={values.updatedBy?values.updatedBy:""} 
                      />
                    </div>  
                    <div className="form-group">
                        <label>Last Updated On:</label>
                        <DatePicker className="form-control" selected={values.updatedOn?new Date(values.updatedOn):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>                
                </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={saveChange}>Save changes</button>
        </div>
        </div>
    </div>
    </div>


    <div className="modal fade" id="searchParticipantModalLong" tabIndex="-1" role="dialog" aria-labelledby="searchParticipantModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="searchParticipantModalLong">Select a Participant</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="card-body">
                    <ul style={{paddingInlineStart:"0em"}}>
                        {prop.participants.map((p) => {
                            return (<li data-dismiss="modal" key={p.id}
                                style={{cursor:"pointer", background:"#138496", padding:"3px", margin:"1px", color:"white"}}
                                onClick={() => {
                                    setValues({
                                        ...values,
                                        participantId: p.id?Number(p.id):null,
                                        name: p.name,
                                        mobile: p.mobile?Number(p.mobile):null,
                                        address: p.address,
                                    });
                                }}>
                                {p.name} - {p.mobile}
                            </li>);
                        })}
                    </ul>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    </div>

    <div className="modal fade" id="tenderPaymentModalLong" tabIndex="-1" role="dialog" aria-labelledby="tenderPaymentModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="tenderPaymentModalLong">Payment For</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
                <div className="card-body">  
                    <div className="form-group">
                        <label>Name:</label>
                      <input type="text" className="form-control" id="name" placeholder="Name" disabled
                      value={tenderPayment.name} 
                      />
                    </div>          
                    <div className="form-group">
                        <label>Mobile:</label>
                      <input type="text" className="form-control" id="mobile" placeholder="Mobile" disabled
                      value={tenderPayment.mobile ? tenderPayment.mobile : ""} 
                      />
                    </div>
                    <div className="form-group">
                        <label>Period:</label>
                      <input type="text" className="form-control" id="period" placeholder="Period" disabled
                      value={periodFormat(tenderPayment.period)} 
                      />
                    </div>
                    <div className="form-group">
                        <label>Paid Amount:</label>
                      <input type="text" className="form-control" id="paid" placeholder="Enter the paid amount for this period"
                      value={tenderPayment.paid}
                      onChange={(evt) => handlePaymentChange('paid', evt.currentTarget.value)}/>
                    </div> 
                    <div className="form-group">
                        <label>Balance Amount:</label>
                      <input type="text" className="form-control" id="balance" placeholder="Enter the balance amount for this period"
                      value={tenderPayment.balance}
                      onChange={(evt) => handlePaymentChange('balance', evt.currentTarget.value)}/>
                    </div>   
                    {/* <div className="form-group">
                        <label>Entry By:</label>
                      <input type="text" className="form-control" id="createdBy" disabled
                      value={tenderPayment.createdBy} 
                      />
                    </div>
                    <div className="form-group">
                        <label>Entry On:</label>
                        <DatePicker className="form-control" selected={tenderPayment.createdOn?new Date(tenderPayment.createdOn):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div> */}
                    <div className="form-group">
                        <label>Last Updated By:</label>
                      <input type="text" className="form-control" id="updatedBy" disabled
                      value={tenderPayment.updatedBy} 
                      />
                    </div>  
                    <div className="form-group">
                        <label>Last Updated On:</label>
                        <DatePicker className="form-control" selected={tenderPayment.updatedOn?new Date(tenderPayment.updatedOn):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>                
                </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={savePaymentChange}>Save changes</button>
        </div>
        </div>
    </div>
    </div>
    </>);
};

const mapStateToProps = (state) => ({
    selectedPhase: state.Phase.selectedPhase,
    participants: state.Participant.participants,
    tenders: state.Tender.tenders,
    skip: state.Tender.skip,
    completed: state.Tender.completed,
    payments: state.Tender.payments
});

const mapDispatchToProps = (dispatch) => ({
    setActiveScreen: (data) => { setTimeout(()=> {dispatch(setActiveScreen(data));}, 1); },
    loadParticipant: (skip, span, field, term, active) => {
        dispatch(getParticipants(skip, span, field, term, active));
    },
    loadTender: (skip, span, field, term, active, phase, free, tender, winner) => {
        dispatch(getTenders(skip, span, 
            field, term, active, 
            phase?formatDateToYYYYMMDD(new Date(phase)):"",
            free, tender, winner));
    },
    loadTenderPayment: (tenderNos, periods) => {
        dispatch(getTenderPayments(tenderNos, periods));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Tender);