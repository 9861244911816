import React, {useEffect, useRef} from "react";
import Header from "./header";
import $ from 'jquery'; 
import {connect} from "react-redux";
import {getParticipants, setActiveScreen} from "../store/actions/index";
import {saveParticipant, updateParticipant} from "../services/participant";

const Participant = (prop) => { 
    prop.setActiveScreen("participant");
    const searchSpan = 10;
    const searchFieldEle = useRef(null);
    const searchActiveEle = useRef(null);
    const searchTermEle = useRef(null);
    useEffect(() => {
		prop.loadParticipant(0, searchSpan, "", "", "");
	}, []);

    let formData = {
        name: "", mobile: "", address: "", active: true
    }; 
    const [values, setValues] = React.useState(formData);
    const handleChange = (field, val) => {
        if(field){
            setValues({
            ...values, [field]: val
            });
        }
    };

    const saveChange = () => {
        if(values.id){
            updateParticipant(values)
            .then(() => {
                prop.loadParticipant(0, searchSpan, "", "", "");
            })
            .catch(() => {

            });
        } else {
            saveParticipant(values)
            .then(() => {
                prop.loadParticipant(0, searchSpan, "", "", "");
            })
            .catch(() => {

            });
        }
        $('#participantModalLong').modal('hide');
    };

    let searchData = {
        field: "", term: "", active: ""
    }; 
    const [search, setSearch] = React.useState(searchData);
    const onSearch = () => {
        const field = searchFieldEle.current.value,
        active = searchActiveEle.current.value?(searchActiveEle.current.value=='true'):"",
        term = searchTermEle.current.value;
        if(term && field){
            setSearch({
                ...search,
                field,
                active,
                term
            });
            prop.loadParticipant(0, searchSpan, field, term, active);
        }
    };

    const formatStatus = (st) => {
        if(st===1) return "Yes";
        else return "No";
    };

    const viewDetail = (selectedData) => {
        setValues({
            ...values,
            id: selectedData.id,
            name: selectedData.name,
            mobile: selectedData.mobile,
            address: selectedData.address,
            active: selectedData.active
        });
        $('#participantModalLong').modal('show');
    };

    const addNew = () => {
        setValues({
            ...values,
            id: undefined,
            name: "",
            mobile: "",
            address: "",
            active: true
        });
        $('#participantModalLong').modal('show');
    };

    const loadMore = () => {
        prop.loadParticipant(prop.skip, searchSpan, search.field, search.term, search.active);
    };

    const onReset = () => {
        searchFieldEle.current.value = "";
        searchActiveEle.current.value = "";
        searchTermEle.current.value = "";
        setSearch({
                ...search,
                ...searchData
            });
        prop.loadParticipant(0, searchSpan, "", "", "");
    };

    return (<>    
    <Header></Header>    
    <div className="container">
        <div className="form-row">
            <div className="form-group col-md-3">
                <select ref={searchFieldEle} className="form-control">
                    <option value="">Search by...</option>
                    <option value="name">Name</option>
                    <option value="mobile">Mobile</option>
                </select>
            </div>
            <div className="form-group col-md-2">
                <select ref={searchActiveEle} className="form-control">
                    <option value="">All Status...</option>
                    <option value="true">Only Active</option>
                    <option value="false">Only Inactive</option>
                </select>
            </div>
            <div className="form-group col-md-5">
                <input ref={searchTermEle} type="text" className="form-control" placeholder="Enter search text" id="term"/>
            </div>            
            <div className="form-group col-md-1">
                <button type="submit" className="btn btn-success" onClick={onSearch}>Search</button>
            </div>
            <div className="form-group col-md-1">
                <button type="submit" className="btn btn-secondary" onClick={onReset}>Reset</button>
            </div>
        </div>

        <button className="btn btn-primary btn-block" type="button" 
        style={{width:"100px", marginBottom:"10px"}} onClick={addNew}
        >
            Add New
        </button>
        <table className="table">
            <thead className="thead-dark">
                <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Address</th>
                <th scope="col">Active</th>
                </tr>
            </thead>
            <tbody>
                {prop.participants.map((p) => {
                    return (
                        <tr key={p.id}>
                            <th scope="row">{p.id}</th>
                            <td>
                                <button type="button" className="btn btn-link"
                                onClick={() => {viewDetail(p)}}>
                                    {p.name}
                                </button>
                            </td>
                            <td>{p.mobile}</td>
                            <td>{p.address}</td>
                            <td>{formatStatus(p.active)}</td>
                        </tr>
                    );
                })}                
            </tbody>
        </table>
        {prop.participants && prop.participants.length===0 
        ? (<div style={{backgroundColor:"rgb(0,0,0,0.5)", width:"100%", color:"white", textAlign:"center"}}>
            No data...
        </div>)
        :
            (prop.completed 
            ? (<div style={{color:"white", backgroundColor:"rgb(0,0,0,0.5)", textAlign:"center"}}>Thats all...</div>)
            : (<button type="button" className="btn btn-link" style={{backgroundColor:"rgb(0,0,0,0.5)", width:"100%"}}
            onClick={loadMore}>
                Load More...
            </button>))
        }
    </div>






    <div className="modal fade" id="participantModalLong" tabIndex="-1" role="dialog" aria-labelledby="participantModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="participantModalLong">Participant</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
                <div className="card-body">     
                    <div className="form-group">
                      <input type="text" className="form-control" id="name" placeholder="Enter Name"
                      value={values.name} onChange={(evt) => handleChange('name', evt.currentTarget.value)}
                      />
                    </div>             
                    <div className="form-group">
                      <input type="text" className="form-control" id="mobile" placeholder="Enter mobile number"
                      value={values.mobile} onChange={(evt) => handleChange('mobile', evt.currentTarget.value)}
                      />
                    </div>
                    <div className="form-group">
                      <textarea type="text" row={100} className="form-control" id="address" placeholder="Enter Address"
                      value={values.address} onChange={(evt) => handleChange('address', evt.currentTarget.value)}
                      />
                    </div>
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="active" checked={values.active}
                      onChange={(evt) => handleChange('active', evt.currentTarget.checked)}/>
                      <label className="form-check-label" htmlFor="active">Active</label>
                    </div>                    
                </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={saveChange}>Save changes</button>
        </div>
        </div>
    </div>
    </div>
    </>);
};

const mapStateToProps = (state) => ({
    participants: state.Participant.participants,
    skip: state.Participant.skip,
    completed: state.Participant.completed
});

const mapDispatchToProps = (dispatch) => ({
    setActiveScreen: (data) => { setTimeout(()=> {dispatch(setActiveScreen(data));}, 1); },
    loadParticipant: (skip, span, field, term, active) => {
        dispatch(getParticipants(skip, span, field, term, active));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Participant);