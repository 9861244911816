import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  openErrorPopup: false,
  errorMsg: "",
  profile: {},
  participants: [],
  skip: 0,
  completed: false,
  screen: ""
};

const Participant = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_SCREEN:
      return updateObject(state, {screen: action.screen});
    case actionTypes.STORE_PARTICIPANT:
      return updateObject(state, { 
        participants: action.participants, 
        skip: action.skip,
        completed:false 
      });
    case actionTypes.UPDATE_PARTICIPANT:
      const updatedData = [...state.participants, ...action.participants];
      return updateObject(state, { 
        participants: updatedData, 
        skip: action.skip,
        completed: (action.participants && action.participants.length==0)
      });

    case actionTypes.STORE_PROFILE:
      return updateObject(state, { profile: action.profile });

    case actionTypes.OPEN_ERROR_POPUP:
      return updateObject(state, { errorMsg: action.msg, openErrorPopup: true });

    case actionTypes.CLOSE_ERROR_POPUP:
      return updateObject(state, { errorMsg: "", openErrorPopup: false });

    default:
      return state;
  }
};

export default Participant;
