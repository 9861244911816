import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  // openErrorPopup: false,
  // errorMsg: "",
  loans: [],
  skip: 0,
  completed: false
};

const Loan = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_LOAN:
      return updateObject(state, { 
        loans: action.loans, 
        skip: action.skip,
        completed:false 
      });
    case actionTypes.UPDATE_LOAN:
      const updatedData = [...state.loans, ...action.loans];
      return updateObject(state, { 
        loans: updatedData, 
        skip: action.skip,
        completed: (action.loans && action.loans.length==0)
      });

    // case actionTypes.OPEN_ERROR_POPUP:
    //   return updateObject(state, { errorMsg: action.msg, openErrorPopup: true });

    // case actionTypes.CLOSE_ERROR_POPUP:
    //   return updateObject(state, { errorMsg: "", openErrorPopup: false });

    default:
      return state;
  }
};

export default Loan;
