import {axiosApi} from "./api";

export const getProfile = () => {
    return axiosApi.get("/api/profile");
};

export const getParticipantList = (skip, span, field, term, active) => {
    return axiosApi.get(`/api/participant?skip=${skip}&span=${span}&field=${field}&term=${term}&active=${active}`);
};

export const saveParticipant = (data) => {
    return axiosApi.post("/api/participant", data);
};

export const updateParticipant = (data) => {
    return axiosApi.put("/api/participant", data);
};

export const formatDateToYYYYMMDD = (dateObj) => {
    if(dateObj) {
        let date = dateObj.getDate(); date = date<10 ? "0"+date : ""+date;
        let month = dateObj.getMonth() - (-1); month = month<10 ? "0"+month : ""+month;
        let year = dateObj.getFullYear();
        return `${year}-${month}-${date}`;
    }
    return null;
};

export const formatDateToDDMMYYYY = (dateObj) => {
    if(dateObj) {
        let date = dateObj.getDate(); date = date<10 ? "0"+date : ""+date;
        let month = dateObj.getMonth() - (-1); month = month<10 ? "0"+month : ""+month;
        let year = dateObj.getFullYear();
        return `${date}/${month}/${year}`;
    }
    return null;
};