import React from "react";
import history from "./history";
import Header from "./header";
import {connect} from "react-redux";
import {getProfile, setActiveScreen} from "../store/actions/index";

const Dashboard = (prop) => {
	prop.setActiveScreen("dashboard");
    return (<>	
	<Header></Header>
	<div className="container">
		<div className="row justify-content-center">
			<div className="col-md-10">
				<h2 className="text-center my-4"></h2>
				<div className="row">
					<div className="col-md-4">
						<div className="tile">
							<h3>Participant</h3>
							<img src={"../static/member-20230513152412.png"} width="100" height="100"></img>
							<button className="btn btn-primary btn-block" onClick={() => history.push("/participant")}>View</button>
						</div>
					</div>
					<div className="col-md-4">
						<div className="tile">
							<h3>Loan</h3>
							<img src={"../static/loan-2023051315220.png"} width="100" height="100"></img>
							<button className="btn btn-primary btn-block" onClick={() => history.push("/loan")}>View</button>
						</div>
					</div>
					<div className="col-md-4">
						<div className="tile">
							<h3>Chit Fund</h3>
							<img src={"../static/chit-fund.png"} width="100" height="100"></img>
							<button className="btn btn-primary btn-block" onClick={() => history.push("/chitfund")}>View</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</>);
};


const mapStateToProps = (state) => ({
	profile: state.Participant.profile
});

const mapDispatchToProps = (dispatch) => ({
	setActiveScreen: (data) => { setTimeout(()=> {dispatch(setActiveScreen(data));}, 1); },
	loadProfile: () => {
		dispatch(getProfile());
	},
});
  

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);