import React from "react";
import {signupUser} from "../../services/auth";
import history from "../history";

const Signup = () => {
  let formData = {
    mobile: "", email: "", name: "", password: "", confirmPassword: "", errorMsg: "", successMsg: "" 
  }; 
  const [values, setValues] = React.useState(formData);
  const handleChange = (field, val) => {
    if(field){
      setValues({
        ...values, [field]: val, errorMsg: "", successMsg: ""
      });
    }
  };

  const submitUser = () => {
    if(!values.mobile || !values.email || !values.password){
      setValues({
        ...values, errorMsg: 'Mobile, Email Address & Password are required!'
      });
    } else if(values.password != values.confirmPassword) {
      setValues({
        ...values, errorMsg: 'Password confirmation is not matching!'
      });
    } else {
      const payload = {
        mobile: values.mobile, email: values.email, name: values.name, password: values.password
      };
      signupUser(payload)
      .then((res) => {
        if(res && res.status == 200){
          setValues({
            ...values, 
            mobile: "", email: "", name: "", password: "", confirmPassword: "", 
            successMsg: (res.data && res.data.msg) ? res.data.msg : "Successfully signed up!"
          });
        }
      })
      .catch((error) => {
        let errorMsg = 'Failed to sign up!';
        if(error && error.response && error.response.data && error.response.data.msg){
          errorMsg = error.response.data.msg;
        }
        setValues({
          ...values, errorMsg
        });
      });
    }
  };

    return (<>
      <div className="login">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card mt-5">
                <div className="card-header">
                  <h4 className="text-center">SIGN UP! to Makhong Mari</h4>
                </div>
                <div className="card-body">
                    <div className="form-group">
                      <input type="number" className="form-control" id="mobile" placeholder="Enter mobile number*" maxLength={10}
                      value={values.mobile} onChange={(evt) => handleChange('mobile', evt.currentTarget.value)}/>
                    </div>
                    <div className="form-group">
                      <input type="email" className="form-control" id="email" placeholder="Enter email address*"
                      value={values.email} onChange={(evt) => handleChange('email', evt.currentTarget.value)}/>
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="name" placeholder="Enter name"
                      value={values.name} onChange={(evt) => handleChange('name', evt.currentTarget.value)}/>
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" id="password" placeholder="Enter password*"
                      value={values.password} onChange={(evt) => handleChange('password', evt.currentTarget.value)}/>
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" id="confirm_password" placeholder="Confirm password*"
                      value={values.confirmPassword} onChange={(evt) => handleChange('confirmPassword', evt.currentTarget.value)}/>
                    </div>
                    <div style={{paddingBottom:"5px"}}>
                      <span style={{color:"red"}}>{values.errorMsg}</span>
                      <span style={{color:"green"}}>{values.successMsg}</span>
                    </div>
                    <button className="btn btn-primary btn-block" onClick={submitUser}>Submit</button>
                    <div>
                      <span style={{paddingTop: "20px"}}>Already signed up?</span> 
                      <button type="button" className="btn btn-link"
                      onClick={() => {history.push("/")}}>
                        Sign In
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
};
  
export default Signup;
  