import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Login from "./auth/login";
import Signup from "./auth/signup";
import Dashboard from "./dashboad";
import Participant from "./participant";
import Loan from "./loan";
import Tender from "./tender";
import $ from 'jquery'; 
import {connect} from "react-redux";
import {getProfile, closePopup} from "../store/actions/index";

const PageContainer = (prop) => { 
  if(prop.openErrorPopup){
    $('#msg-popup').modal('show');
  } else {
    $('#msg-popup').modal('hide');
  }

  $('#msg-popup').on('hidden.bs.modal', function (e) {
    closePopup();
  })

  return (<>
    <Router history = {history}>
    <div className="page-container">
      <Switch>
        {/* <Route
          exact
          path="/dataexchange/:id"
          render={(props) => <DataExchangeRoot {...props} />}
        ></Route> */}
        <Route exact path="/" component={Login}></Route>
        <Route exact path="/signup" component={Signup}></Route>
        <Route exact path="/dashboard" component={Dashboard}></Route>
        <Route exact path="/participant" component={Participant}></Route>
        <Route exact path="/loan" component={Loan}></Route>
        <Route exact path="/chitfund" component={Tender}></Route>
      </Switch>
    </div>
    </Router>

    <div className="modal fade" id="msg-popup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {prop.errorMsg}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal"
            onClick={() => {}}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
	openErrorPopup: state.Participant.openErrorPopup,
  errorMsg: state.Participant.errorMsg
});

const mapDispatchToProps = (dispatch) => ({
	loadProfile: () => {
		dispatch(getProfile());
	},
});
  

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
