import React from "react";
import PageContainer from "./pageContainer";
import { BrowserRouter as Router } from "react-router-dom";

const Layout = () => {
  return (
    <Router basename="/">
      <React.Fragment>
        <PageContainer></PageContainer>
      </React.Fragment>
    </Router>
  );
};

export default Layout;
