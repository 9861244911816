import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  openErrorPopup: false,
  errorMsg: "",
  phase: [],
  selectedPhase: {}
};

const Phase = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_PHASE:
      return updateObject(state, { 
        phase: action.phase
      });

    case actionTypes.SELECT_PHASE:
      return updateObject(state, { 
        selectedPhase: action.selectedPhase
      });
    default:
      return state;
  }
};

export default Phase;
