import {axiosApi} from "./api";

export const getTenderList = (skip, span, field, term, active, phase, free, tender, winner) => {
    return axiosApi.get(`/api/tender?skip=${skip}&span=${span}&field=${field}&term=${term}&active=${active}&phase=${phase}&free=${free}&tender=${tender}&winner=${winner}`);
};

export const saveTender = (data) => {
    return axiosApi.post("/api/tender", data);
};

export const updateTender = (data) => {
    return axiosApi.put("/api/tender", data);
};

export const loadTenderPayments = (data) => {
    return axiosApi.post("/api/tenderpayment/load", data);
};
export const updateTenderPayment = (data) => {
    return axiosApi.put("/api/tenderpayment", data);
};

export const getPaymentDetail = (tenderNo) => {
    return axiosApi.get("/api/tenderpayment/detail/"+tenderNo);
};