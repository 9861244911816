import React, {useEffect, useRef} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./header";
import $ from 'jquery'; 
import {connect} from "react-redux";
import {getLoans, getParticipants, setActiveScreen} from "../store/actions/index";
import {saveLoan, updateLoan} from "../services/loan";
import {formatDateToYYYYMMDD, formatDateToDDMMYYYY} from "../services/participant";

const Loan = (prop) => { 
    prop.setActiveScreen("loan");
    $('#searchParticipantModalLong').on('hidden.bs.modal', function (e) {
        $("#loanModalLong").modal('show');
    });
    $('#searchParticipantModalLong').on('show.bs.modal', function (e) {
        $("#loanModalLong").modal('hide');
    });

    const searchSpan = 10;
    useEffect(() => {
        prop.loadParticipant(0, searchSpan, "", "", "");
		prop.loadLoan(0, searchSpan, "", "", "", prop.selectedPhase.startDate);
	}, [prop.selectedPhase]);

    let formData = {
        participantId: "", name: "", address: "", mobile: "", cardNo: "", 
        sanctionedDate: "", amount: "", percentage: "", 
        outstanding: "", phaseDate: prop.selectedPhase?prop.selectedPhase.startDate:null, active: true,
        createdOn: "", createdBy: "",
        updatedOn: "", updatedBy: ""
    }; 
    const [values, setValues] = React.useState(formData);
    const handleChange = (field, val) => {
        if(field){
            if(field==="sanctionedDate"){
                setValues({
                    ...values, [field]: formatDateToYYYYMMDD(val)
                    });
            } else {
                setValues({
                ...values, [field]: val
                });
            }
        }
    };

    const saveChange = () => {
        if(!values.participantId)
            return false;

        if(values.bookNo){
            const postData = {
                bookNo: values.bookNo,
                participantId: values.participantId, mobile: values.mobile, cardNo: values.cardNo, 
                sanctionedDate: values.sanctionedDate, amount: values.amount, percentage: values.percentage, 
                outstanding: values.outstanding, phaseDate: formatDateToYYYYMMDD(new Date(values.phaseDate)), active: values.active
            };
            
            updateLoan(postData)
            .then(() => {
                prop.loadLoan(0, searchSpan, "", "", "", prop.selectedPhase.startDate);
            })
            .catch(() => {

            });
        } else {
            const postData = {
                participantId: values.participantId, mobile: values.mobile, cardNo: values.cardNo, 
                sanctionedDate: values.sanctionedDate, amount: values.amount, percentage: values.percentage, 
                outstanding: values.outstanding, phaseDate: formatDateToYYYYMMDD(new Date(values.phaseDate)), active: true
            };
            
            saveLoan(postData)
            .then(() => {
                prop.loadLoan(0, searchSpan, "", "", "", prop.selectedPhase.startDate);
            })
            .catch(() => {

            });
        }
        $('#loanModalLong').modal('hide');
    };

    let searchData = {
        field: "", term: "", active: ""
    }; 
    const [search, setSearch] = React.useState(searchData);
    const onSearch = () => {
        const field = search.field,
        active = search.active,
        term = search.term;
        if(term && field){
            setSearch({
                ...search,
                field,
                active,
                term
            });
            prop.loadLoan(0, searchSpan, field, term, active, prop.selectedPhase.startDate);
        }
    };

    const formatStatus = (st) => {
        if(st===1) return "Yes";
        else return "No";
    };

    const viewDetail = (selectedData) => {
        setValues({
            ...values,
            bookNo: selectedData.bookNo,
            participantId: selectedData.participantId,
            name: selectedData.name,
            mobile: selectedData.mobile,
            address: selectedData.address,
            cardNo: selectedData.cardNo,
            sanctionedDate: selectedData.sanctionedDate,
            amount: selectedData.amount,
            percentage: selectedData.percentage,
            outstanding: selectedData.outstanding,
            phaseDate: selectedData.phaseDate,
            active: selectedData.active,
            createdBy: selectedData.createdBy,
            createdOn: selectedData.createdOn,
            updatedBy: selectedData.updatedBy,
            updatedOn: selectedData.updatedOn
        });
        $('#loanModalLong').modal('show');
    };

    const addNew = () => {
        setValues({
            ...values,
            bookNo: undefined,
            participantId: "",
            name: "",
            mobile: "",
            address: "",
            cardNo: "",
            sanctionedDate: "",
            amount: "",
            percentage: "",
            outstanding: "",
            phaseDate: (prop.selectedPhase)?prop.selectedPhase.startDate:"",
            active: true,
            createdBy: "",
            createdOn: "",
            updatedBy: "",
            updatedOn: ""
        });
        $('#loanModalLong').modal('show');
    };

    const loadMore = () => {
        prop.loadLoan(prop.skip, searchSpan, search.field, search.term, search.active, prop.selectedPhase.startDate);
    };

    const onReset = () => {
        setSearch({
                ...search,
                ...searchData
            });
        prop.loadLoan(0, searchSpan, "", "", "", prop.selectedPhase.startDate);
    };

    return (<>    
    <Header></Header>   
    <div style={{background:"rgb(255,255,255,0.5)", fontSize:"20px", fontWeight:"900", textAlign:"center", margin:"10px", padding:"5px"}}>
        LOAN
    </div> 
    {prop.selectedPhase&&prop.selectedPhase.id
    ? (
    <div className="container">
        <div className="form-row">
            <div className="form-group col-md-3">
                <select className="form-control" value={search.field} 
                onChange={(evt) => {
                    setSearch({ ...search, field: evt.currentTarget.value });
                }}>
                    <option value="">Search by...</option>
                    <option value="name">Name</option>
                    <option value="mobile">Mobile</option>
                    <option value="bookNo">Book No</option>
                    <option value="cardNo">Card No</option>
                    <option value="sanctionedDate">Sanctioned On</option>
                </select>
            </div>
            <div className="form-group col-md-2">
                <select className="form-control" value={search.active} 
                onChange={(evt) => {
                    setSearch({ ...search, active: evt.currentTarget.value });
                }}>
                    <option value="">All Status...</option>
                    <option value="true">Only Active</option>
                    <option value="false">Only Inactive</option>
                </select>
            </div>
            <div className="form-group col-md-5">
                {search.field==="sanctionedDate"
                ?(<DatePicker className="form-control" selected={search.term?new Date(search.term):""} dateFormat="dd/MM/yyyy" 
                    onChange={(date) => {
                        setSearch({ ...search, term: formatDateToYYYYMMDD(date) });
                    }} />)
                :(<input type="text" className="form-control" placeholder="Enter search text" value={search.term} 
                    onChange={(evt) => {
                        setSearch({ ...search, term: evt.currentTarget.value });
                    }} />)
                }
            </div>            
            <div className="form-group col-md-1">
                <button type="submit" className="btn btn-success" onClick={onSearch}>Search</button>
            </div>
            <div className="form-group col-md-1">
                <button type="submit" className="btn btn-secondary" onClick={onReset}>Reset</button>
            </div>
        </div>

        <button className="btn btn-primary btn-block" type="button" 
        style={{width:"100px", marginBottom:"10px"}} onClick={addNew}
        >
            Add New
        </button>
        <table className="table">
            <thead className="thead-dark">
                <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Card#</th>
                <th scope="col">Sanctioned Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Percentage</th>
                <th scope="col">Outstanding</th>
                <th scope="col">Phase Starting</th>
                <th scope="col">Active</th>
                </tr>
            </thead>
            <tbody>
                {prop.loans.map((p) => {
                    return (
                        <tr key={p.bookNo}>
                            <th scope="row">{p.bookNo}</th>
                            <td>
                                <button type="button" className="btn btn-link"
                                onClick={() => {viewDetail(p)}}>
                                    {p.name}
                                </button>
                            </td>
                            <td>{p.mobile}</td>
                            <td>{p.cardNo}</td>
                            <td>{p.sanctionedDate?formatDateToDDMMYYYY(new Date(p.sanctionedDate)):""}</td>
                            <td>{p.amount}</td>
                            <td>{p.percentage}</td>
                            <td>{p.outstanding}</td>
                            <td>{p.phaseDate?formatDateToDDMMYYYY(new Date(p.phaseDate)):""}</td>
                            <td>{formatStatus(p.active)}</td>
                        </tr>
                    );
                })}                
            </tbody>
        </table>
        {prop.loans && prop.loans.length===0 
        ?(<div style={{backgroundColor:"rgb(0,0,0,0.5)", width:"100%", color:"white", textAlign:"center"}}>
            No data...
        </div>)
        :
            (prop.completed 
            ? (<div style={{color:"white", backgroundColor:"rgb(0,0,0,0.5)", textAlign:"center"}}>Thats all...</div>)
            : (<button type="button" className="btn btn-link" style={{backgroundColor:"rgb(0,0,0,0.5)", width:"100%"}}
            onClick={loadMore}>
                Load More...
            </button>))
        }
    </div>
    )
    : (<div style={{background:"rgb(0,0,0,0.5)", color:"white", textAlign:"center", margin:"20px"}}>
        Please select a phase to continue...
    </div>)}





    <div className="modal fade" id="loanModalLong" tabIndex="-1" role="dialog" aria-labelledby="loanModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="loanModalLong">Loan</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
                <div className="card-body">     
                    <div className="form-group">
                        <button type="button" className="btn btn-info" style={{width:"100%"}}
                        data-toggle="modal" data-target="#searchParticipantModalLong">
                            {values.name?values.name:"Click to select Participant..."}
                        </button>
                    </div>             
                    <div className="form-group">
                      <input type="text" className="form-control" id="mobile" placeholder="Enter mobile number" disabled
                      value={values.mobile} 
                      />
                    </div>
                    <div className="form-group">
                      <textarea type="text" row={100} className="form-control" id="address" placeholder="Enter Address" disabled
                      value={values.address} 
                      />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" id="cardNo" placeholder="Enter card number" 
                      value={values.cardNo} onChange={(evt) => handleChange('cardNo', evt.currentTarget.value)}
                      />
                    </div>
                    <div className="form-group">
                        <label>Sanctioned On:</label>
                        <DatePicker className="form-control" selected={values.sanctionedDate?new Date(values.sanctionedDate):""} 
                        dateFormat="dd/MM/yyyy" 
                        onChange={(date) => handleChange('sanctionedDate', date)} />
                    </div>
                    <div className="form-group">
                      <input type="number" className="form-control" id="amount" placeholder="Enter Amount" 
                      value={values.amount} onChange={(evt) => handleChange('amount', evt.currentTarget.value+"")}
                      />
                    </div>
                    <div className="form-group">
                      <input type="number" className="form-control" id="percentage" placeholder="Enter Percentage" 
                      value={values.percentage} onChange={(evt) => handleChange('percentage', evt.currentTarget.value+"")}
                      />
                    </div>
                    <div className="form-group">
                      <input type="number" className="form-control" id="outstanding" placeholder="Enter Outstanding" 
                      value={values.outstanding} onChange={(evt) => handleChange('outstanding', evt.currentTarget.value+"")}
                      />
                    </div>
                    <div className="form-group">
                        <label>Phase Starting:</label>
                        <DatePicker className="form-control" selected={values.phaseDate?new Date(values.phaseDate):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="active" checked={values.active}
                      onChange={(evt) => handleChange('active', evt.currentTarget.checked)}/>
                      <label className="form-check-label" htmlFor="active">Active</label>
                    </div>  
                    <div className="form-group">
                        <label>Entry By:</label>
                      <input type="text" className="form-control" id="createdBy" disabled
                      value={values.createdBy} 
                      />
                    </div>
                    <div className="form-group">
                        <label>Entry On:</label>
                        <DatePicker className="form-control" selected={values.createdOn?new Date(values.createdOn):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>
                    <div className="form-group">
                        <label>Last Updated By:</label>
                      <input type="text" className="form-control" id="updatedBy" disabled
                      value={values.updatedBy} 
                      />
                    </div>  
                    <div className="form-group">
                        <label>Last Updated On:</label>
                        <DatePicker className="form-control" selected={values.updatedOn?new Date(values.updatedOn):""} disabled 
                         dateFormat="dd/MM/yyyy" />
                    </div>                
                </div>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={saveChange}>Save changes</button>
        </div>
        </div>
    </div>
    </div>


    <div className="modal fade" id="searchParticipantModalLong" tabIndex="-1" role="dialog" aria-labelledby="searchParticipantModalLong" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="searchParticipantModalLong">Select a Participant</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="card-body">
                    <ul style={{paddingInlineStart:"0em"}}>
                        {prop.participants.map((p) => {
                            return (<li data-dismiss="modal" key={p.id}
                                style={{cursor:"pointer", background:"#138496", padding:"3px", margin:"1px", color:"white"}}
                                onClick={() => {
                                    setValues({
                                        ...values,
                                        participantId: p.id?Number(p.id):null,
                                        name: p.name,
                                        mobile: p.mobile?Number(p.mobile):null,
                                        address: p.address,
                                    });
                                }}>
                                {p.name} - {p.mobile}
                            </li>);
                        })}
                    </ul>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    </div>
    </>);
};

const mapStateToProps = (state) => ({
    selectedPhase: state.Phase.selectedPhase,
    participants: state.Participant.participants,
    loans: state.Loan.loans,
    skip: state.Loan.skip,
    completed: state.Loan.completed
});

const mapDispatchToProps = (dispatch) => ({
    setActiveScreen: (data) => { setTimeout(()=> {dispatch(setActiveScreen(data));}, 1); },
    loadParticipant: (skip, span, field, term, active) => {
        dispatch(getParticipants(skip, span, field, term, active));
    },
    loadLoan: (skip, span, field, term, active, phase) => {
        dispatch(getLoans(skip, span, 
            field, term, active, 
            phase?formatDateToYYYYMMDD(new Date(phase)):""));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Loan);