import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  // openErrorPopup: false,
  // errorMsg: "",
  tenders: [],
  skip: 0,
  completed: false,
  payments: {}
};

const Tender = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_TENDER:
      return updateObject(state, { 
        tenders: action.tenders, 
        skip: action.skip,
        completed:false 
      });
    case actionTypes.UPDATE_TENDER:
      const updatedData = [...state.tenders, ...action.tenders];
      return updateObject(state, { 
        tenders: updatedData, 
        skip: action.skip,
        completed: (action.tenders && action.tenders.length==0)
      });

    case actionTypes.STORE_TENDER_PAYMENT:
      const updatedPaymentData = {...state.payments, ...action.payments};
      return updateObject(state, { 
        payments: updatedPaymentData
      });

    // case actionTypes.OPEN_ERROR_POPUP:
    //   return updateObject(state, { errorMsg: action.msg, openErrorPopup: true });

    // case actionTypes.CLOSE_ERROR_POPUP:
    //   return updateObject(state, { errorMsg: "", openErrorPopup: false });

    default:
      return state;
  }
};

export default Tender;
