import * as actionTypes from "./actionTypes";
import * as loanService from "../../services/loan";

export const storeLoanList = (data, skip) => {
  return {
    type: actionTypes.STORE_LOAN,
    loans: data,
    skip
  };
};
export const updateLoanList = (data, skip) => {
  return {
    type: actionTypes.UPDATE_LOAN,
    loans: data,
    skip
  };
};

export const getLoans = (skip, span, field, term, active, phase) => {
  return (dispatch) => {
    loanService
      .getLoanList(skip, span, field, term, active, phase)
      .then((res) => {    
        if(res && res.data) {
          if(skip)
            dispatch(updateLoanList(res.data.results, res.data.skip));
          else
            dispatch(storeLoanList(res.data.results, res.data.skip));
        }
      })
      .catch((error) => { 
        const errMsg = (error && error.response && error.response.msg) ? error.response.msg : "Unable to load loans!";
        dispatch(errorPopup(errMsg));
      });
  };
};


export const errorPopup = (msg) => {
  return {
    type: actionTypes.OPEN_ERROR_POPUP,
    msg
  };
};

export const closePopup = () => {
  return (dispatch) => dispatch({
    type: actionTypes.CLOSE_ERROR_POPUP
  });
};

