import * as actionTypes from "./actionTypes";
import * as tenderService from "../../services/tender";

export const storeTenderList = (data, skip) => {
  return {
    type: actionTypes.STORE_TENDER,
    tenders: data,
    skip
  };
};
export const updateTenderList = (data, skip) => {
  return {
    type: actionTypes.UPDATE_TENDER,
    tenders: data,
    skip
  };
};

export const storeTenderPayments = (data) => {
  return {
    type: actionTypes.STORE_TENDER_PAYMENT,
    payments: data
  };
};

export const getTenders = (skip, span, field, term, active, phase, free, tender, winner) => {
  return (dispatch) => {
    tenderService
      .getTenderList(skip, span, field, term, active, phase, free, tender, winner)
      .then((res) => {    
        if(res && res.data) {
          if(skip)
            dispatch(updateTenderList(res.data.results, res.data.skip));
          else
            dispatch(storeTenderList(res.data.results, res.data.skip));
        }
      })
      .catch((error) => { 
        const errMsg = (error && error.response && error.response.msg) ? error.response.msg : "Unable to load chit funds!";
        dispatch(errorPopup(errMsg));
      });
  };
};

export const getTenderPayments = (tenderNos, periods) => {
  return (dispatch) => {
    tenderService
      .loadTenderPayments({tenderNos, periods})
      .then((res) => {    
        if(res && res.data) {
            dispatch(storeTenderPayments(res.data.results));
        }
      })
      .catch((error) => { 
        const errMsg = (error && error.response && error.response.msg) ? error.response.msg : "Unable to load payments!";
        dispatch(errorPopup(errMsg));
      });
  };
};


export const errorPopup = (msg) => {
  return {
    type: actionTypes.OPEN_ERROR_POPUP,
    msg
  };
};

export const closePopup = () => {
  return (dispatch) => dispatch({
    type: actionTypes.CLOSE_ERROR_POPUP
  });
};

