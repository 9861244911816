
import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./styles/index.css";
import App from "../src/component/app";
import { Provider } from "react-redux";
import Participant from "./store/reducers/participant";
import Loan from "./store/reducers/loan";
import Tender from "./store/reducers/tender";
import Phase from "./store/reducers/phase";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as actionTypes from "./store/actions/actionTypes";

const reduxStore = createStore(
  combineReducers({
    Participant,
    Loan,
    Tender,
    Phase
  }),
  applyMiddleware(thunk)
);

ReactDOM.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);
