import {axiosApi} from "./api";
import history from "../component/history";

export const signupUser = (data) => {
    return axiosApi.post("/api/signup", data);
};

export const signinUser = (data) => {
    return axiosApi.post("/api/signin", data);
};

const logoutUser = () => {
    return axiosApi.get("/api/logout");
};

export const logout = () => {
    logoutUser()
    .then(() => {
        history.push("/");
    })
    .catch(() => {});
};