import React, {useEffect} from "react";
import {signinUser} from "../../services/auth";
import history from "../history";
import {connect} from "react-redux";
import {checkProfile} from "../../store/actions/index";

const Login = (prop) => { 
  useEffect(() => {
		prop.checkLoggedInProfile();
	}, []);

  let formData = {
    mobile: "", password: "", errorMsg: ""
  }; 
  const [values, setValues] = React.useState(formData);
  const handleChange = (field, val) => {
    if(field){
      setValues({
        ...values, [field]: val, errorMsg: ""
      });
    }
  };

  const loginUser = () => {
    if(!values.mobile || !values.password){
      setValues({
        ...values, errorMsg: 'Please enter Mobile & Password!'
      });      
    } else {
      const payload = {
        mobile: values.mobile, password: values.password
      };
      signinUser(payload)
      .then((res) => {
        if(res && res.status==200)
          history.push("/dashboard");
      })
      .catch((error) => {
        let errorMsg = 'Failed to login!';
        if(error && error.response && error.response.data && error.response.data.msg){
          errorMsg = error.response.data.msg;
        }
        setValues({
          ...values, errorMsg
        });
      });
    }
  };

    return (<>
    <div className="login">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="card mt-5 login-card">
                <div className="card-header">
                  <h5 className="text-center">Welcome to Makhong Mari</h5>
                </div>
                <div className="card-body">                  
                    <div className="form-group">
                      <input type="text" className="form-control" id="mobile" placeholder="Enter mobile number" maxLength={10}
                      value={values.mobile} onChange={(evt) => handleChange('mobile', evt.currentTarget.value)}
                      onKeyDown={(e) => {if(e.key === "Enter") {loginUser()}} }/>
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" id="password" placeholder="Enter password"
                      value={values.password} onChange={(evt) => handleChange('password', evt.currentTarget.value)}
                      onKeyDown={(e) => {if(e.key === "Enter") {loginUser()}} }/>
                    </div>
                    <div className="form-group form-check">
                      <input type="checkbox" className="form-check-input" id="remember"/>
                      <label className="form-check-label" htmlFor="remember">Remember me</label>
                    </div>
                    <div style={{color:"red", paddingBottom:"5px"}}>{values.errorMsg}</div>
                    <button className="btn btn-primary btn-block" onClick={loginUser}>Login</button>
                    <div>
                      <span style={{paddingTop: "20px"}}>Not signed up yet?</span> 
                      <button type="button" className="btn btn-link"
                      onClick={() => {history.push("/signup")}}>
                        Sign Up
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>);
  };
  
  const mapStateToProps = (state) => ({
    profile: state.Participant.profile
  });
  
  const mapDispatchToProps = (dispatch) => ({
    checkLoggedInProfile: () => {
      dispatch(checkProfile());
    },
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Login);
  